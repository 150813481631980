<template>
  <span>
    <v-card
      class="px-3 pt-3"
      elevation="7"
      :class="{
        'background-primary': primary || !secondary,
        'background-secondary': secondary
      }"
      height="fit-content"
    >
      <v-container class="py-0">
        <v-row class="py-0">
          <v-col cols="12" class="py-0 align-end justify-center">
            <v-row>
              <v-col
                :cols="this.$vuetify.breakpoint.xsOnly ? '12' : '8'"
                lg="6"
                class="font-weight-medium"
                :align="this.$vuetify.breakpoint.xsOnly ? 'center' : ''"
                :class="{
                  'headline ': this.$vuetify.breakpoint.lgAndUp,
                  title: this.$vuetify.breakpoint.mdAndDown
                }"
                >{{ title }}</v-col
              >
              <v-col
                :cols="this.$vuetify.breakpoint.xsOnly ? '12' : '4'"
                lg="6"
                class="shadow d-flex align-end justify-center"
                :class="{
                  'display-4': this.$vuetify.breakpoint.lgAndUp,
                  'display-2': this.$vuetify.breakpoint.mdAndDown,
                  'pl-0': this.$vuetify.breakpoint.xsOnly
                }"
                >{{ value }}</v-col
              >
            </v-row>
            <v-row class="pb-0">
              <v-col cols="12" class="pt-0 pb-4 d-flex align-end justify-end">
                <v-progress-linear
                  :value="totalShown"
                  background-color="rgb(167, 167, 167)"
                  color="white"
                ></v-progress-linear>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </span>
</template>

<script>
export default {
  name: "card",
  props: {
    value: {
      type: Number,
      required: true
    },
    total: {
      type: Number,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    primary: {
      type: Boolean,
      default: false
    },
    secondary: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {};
  },
  computed: {
    totalShown() {
      return (this.value * 100) / this.total;
    }
  }
};
</script>
<style scoped>
.background-primary {
  background: rgb(177, 85, 193);
  background: linear-gradient(
    90deg,
    rgba(177, 85, 193, 1) 0%,
    rgba(218, 140, 232, 1) 100%
  );
  color: white;
}
.background-secondary {
  background: rgb(98, 213, 204);
  background: linear-gradient(
    90deg,
    rgba(98, 213, 204, 1) 0%,
    rgba(154, 242, 235, 1) 100%
  );
  color: white;
}
.background-total {
  background: rgb(58, 217, 175);
  background: linear-gradient(
    90deg,
    rgba(58, 217, 175, 1) 0%,
    rgba(55, 210, 96, 1) 100%
  );
  color: white;
}

.shadow {
  -webkit-filter: drop-shadow(10px 10px 8px rgba(0, 0, 0, 0.56));
  filter: drop-shadow(10px 10px 8px rgba(0, 0, 0, 0.56));
}
</style>
