<template>
  <v-card class="pb-3 align-card-height" elevation="7">
    <div
      :align="this.$vuetify.breakpoint.xsOnly ? 'center' : ''"
      :justify="this.$vuetify.breakpoint.xsOnly ? 'center' : ''"
      class="pa-3 title-border-radius"
      :class="{
        headline: this.$vuetify.breakpoint.smAndUp,
        'body-1 font-weight-medium': this.$vuetify.breakpoint.xsOnly,
        'background-primary': primary || !secondary,
        'background-secondary': secondary
      }"
    >
      {{ title }}
    </div>
    <v-container class="pa-0 px-3">
      <v-row>
        <v-col cols="12">
          <apexchart
            type="bar"
            height="350"
            v-if="categories.length && values.length"
            :options="chartOptions"
            :series="series"
          ></apexchart>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
export default {
  name: "bar-chart",
  props: {
    categories: {
      type: Array,
      required: true
    },
    values: {
      type: Array,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    chartName: {
      type: String,
      required: true
    },
    primary: {
      type: Boolean,
      default: false
    },
    secondary: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {};
  },
  computed: {
    series() {
      return [
        {
          name: this.chartName,
          data: this.values
        }
      ];
    },
    chartOptions() {
      return {
        chart: {
          height: 350,
          type: "bar"
        },
        plotOptions: {
          bar: {
            columnWidth: "45%",
            distributed: true
          }
        },
        dataLabels: {
          enabled: true
        },
        legend: {
          show: true
        },
        xaxis: {
          categories: this.categories,
          labels: { show: false }
        }
      };
    }
  },
  mounted() {}
};
</script>
<style scoped>
.background-primary {
  background: rgb(177, 85, 193);
  background: linear-gradient(
    90deg,
    rgba(177, 85, 193, 1) 0%,
    rgba(218, 140, 232, 1) 100%
  );
  color: white;
}
.background-secondary {
  background: rgb(98, 213, 204);
  background: linear-gradient(
    90deg,
    rgba(98, 213, 204, 1) 0%,
    rgba(154, 242, 235, 1) 100%
  );
  color: white;
}
.title-border-radius {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
</style>
