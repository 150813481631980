<template>
  <v-container fluid class="px-8">
    <v-row>
      <v-col cols="12">
        <v-skeleton-loader type="heading" />
      </v-col>
    </v-row>
    <br />
    <hr />
    <br />
    <v-row :class="{ 'px-12': this.$vuetify.breakpoint.lgAndUp }">
      <v-col cols="12" md="6">
        <v-skeleton-loader tile type="image" />
      </v-col>
    </v-row>
    <v-row :class="{ 'px-12': this.$vuetify.breakpoint.lgAndUp }">
      <v-col cols="12" md="6">
        <v-skeleton-loader tile type="image" />
        <v-skeleton-loader tile type="image" />
      </v-col>
      <v-col cols="12" md="6">
        <v-skeleton-loader tile type="image" />
        <v-skeleton-loader tile type="image" />
      </v-col>
    </v-row>
    <v-row :class="{ 'px-12': this.$vuetify.breakpoint.lgAndUp }">
      <v-col cols="12" md="6">
        <v-skeleton-loader tile type="image" />
        <v-skeleton-loader tile type="image" />
      </v-col>
      <v-col cols="12" md="6">
        <v-skeleton-loader tile type="image" />
        <v-skeleton-loader tile type="image" />
      </v-col>
    </v-row>
    <v-row :class="{ 'px-12': this.$vuetify.breakpoint.lgAndUp }">
      <v-col cols="12">
        <v-skeleton-loader tile type="image" />
        <v-skeleton-loader tile type="image" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "dashboard-loader"
};
</script>

<style scoped></style>
