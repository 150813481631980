<template>
  <dashboard />
</template>

<script>
import Dashboard from "@/components/dashboard/Dashboard.vue";

export default {
  name: "dashboard-view",
  components: {
    dashboard: Dashboard
  },
  data() {
    return {};
  }
};
</script>

<style scoped></style>
