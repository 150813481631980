<template>
  <span>
    <dashboard-loader v-if="loading" />
    <error-view v-else-if="errorLoading" />
    <v-container v-else fluid class="px-8">
      <v-lazy :options="{ threshold: 0.5 }" transition="slide-x-transition">
        <v-row>
          <v-col
            cols="12"
            class="headline"
            :align="this.$vuetify.breakpoint.xsOnly ? 'center' : ''"
            :justify="this.$vuetify.breakpoint.xsOnly ? 'center' : ''"
            >Estadísticas Generales</v-col
          >
          <v-col cols="12">
            <hr />
          </v-col>
        </v-row>
      </v-lazy>
      <br />
      <v-lazy
        :options="{ threshold: 0.5 }"
        transition="slide-x-transition"
        min-height="200"
      >
        <v-row :class="{ 'px-12': this.$vuetify.breakpoint.lgAndUp }">
          <v-col cols="12" md="6">
            <card
              :value="totalNumberOfPatients"
              :total="totalNumberOfPatients"
              title="Consultantes"
              secondary
            />
          </v-col>
        </v-row>
      </v-lazy>
      <v-lazy
        :options="{ threshold: 0.5 }"
        transition="slide-x-transition"
        min-height="400"
      >
        <v-row :class="{ 'px-12': this.$vuetify.breakpoint.lgAndUp }">
          <v-col cols="12" md="6">
            <bar-chart
              title="Estado civil"
              chartName="Cantidad de personas"
              :categories="civilStatusOptions"
              :values="civilStatusValues"
              primary
            />
          </v-col>
          <v-col cols="12" md="6">
            <donut-chart
              title="Género"
              secondary
              :categories="genderOptions"
              :values="genderValues"
            />
          </v-col>
        </v-row>
      </v-lazy>
      <v-lazy
        :options="{ threshold: 0.5 }"
        transition="slide-x-transition"
        min-height="400"
      >
        <v-row :class="{ 'px-12': this.$vuetify.breakpoint.lgAndUp }">
          <v-col cols="12" md="6">
            <bar-chart
              title="Posición de hijo"
              chartName="Cantidad de personas"
              :categories="childPositionOptions"
              :values="childPositionValues"
              primary
            />
          </v-col>
          <v-col cols="12" md="6">
            <donut-chart
              title="¿Posee hijos?"
              secondary
              :categories="childrenOptions"
              :values="childrenValues"
            />
          </v-col>
        </v-row>
      </v-lazy>
      <br />
      <br />
      <br />
      <template v-if="booleanStatisticsExist || optionStatisticsExist">
        <v-lazy :options="{ threshold: 0.5 }" transition="slide-x-transition">
          <v-row>
            <v-col
              cols="12"
              class="headline"
              :align="$vuetify.breakpoint.xsOnly ? 'center' : ''"
              :justify="$vuetify.breakpoint.xsOnly ? 'center' : ''"
              >Estadísticas de formulario</v-col
            >
            <v-col cols="12">
              <hr />
            </v-col>
          </v-row>
        </v-lazy>
        <span
          v-for="(section, index) in formStatistics.sections"
          :key="`${index}-${section.id}`"
        >
          <v-lazy :options="{ threshold: 0.5 }" transition="slide-x-transition">
            <v-row>
              <v-col
                cols="12"
                class="headline"
                :align="$vuetify.breakpoint.xsOnly ? 'center' : ''"
                :justify="$vuetify.breakpoint.xsOnly ? 'center' : ''"
                >Sección - {{ section.name }}</v-col
              >
              <v-col cols="12"> </v-col>
            </v-row>
          </v-lazy>
          <v-lazy
            :options="{ threshold: 0.5 }"
            transition="slide-x-transition"
            min-height="400"
          >
            <v-row :class="{ 'px-12': $vuetify.breakpoint.lgAndUp }">
              <v-col
                cols="12"
                md="6"
                v-for="(question, index) in section.questions"
                :key="`${index}-${question.id}`"
              >
                <donut-chart
                  :title="question.name"
                  :secondary="!!(index % 2)"
                  :primary="!(index % 2)"
                  :categories="question.statistics.options"
                  :values="question.statistics.values"
                />
              </v-col>
            </v-row>
          </v-lazy>
          <br />
          <br />
        </span>
      </template>
    </v-container>
  </span>
</template>

<script>
import BarChart from "./BarChart";
import DonutChart from "./DonutChart";
import DashboardLoader from "@/components/common/loaders/DashboardLoader";
import Card from "./Card";
import ErrorView from "@/components/common/notifications/ErrorView";

import Repository from "@/services/repositories/repositoryFactory";
const PatientRepository = Repository.get("patient");

export default {
  name: "dashboard",
  components: {
    "dashboard-loader": DashboardLoader,
    "donut-chart": DonutChart,
    "bar-chart": BarChart,
    "error-view": ErrorView,
    card: Card
  },
  data() {
    return {
      loading: false,
      errorLoading: false,
      optionStatisticsExist: false,
      booleanStatisticsExist: false,
      patientsStatistics: null,
      genderOptions: ["Hombre", "Mujer", "Otro"],
      totalNumberOfPatients: 0,
      formStatistics: { id: null, sections: [] },
      genderValues: [0, 0, 0],
      civilStatusOptions: [
        "Solter@",
        "Casad@",
        "Unión libre / Concubinato",
        "Divorciad@",
        "Viud@",
        "Otro"
      ],
      civilStatusValues: [0, 0, 0, 0, 0, 0],
      childrenOptions: ["Sí", "No", "En gestación"],
      childrenValues: [0, 0, 0],
      childPositionOptions: [
        "Hij@ únic@",
        "Primer@",
        "Segund@",
        "Tercer@",
        "Cuart@",
        "Quint@",
        "Otro"
      ],
      childPositionValues: [0, 0, 0, 0, 0, 0, 0]
    };
  },
  methods: {
    async loadPatientsStatistics() {
      this.loading = true;

      this.patientsStatistics = await PatientRepository.getPatientsStatistics().catch(
        () => {
          this.errorLoading = true;
        }
      );
      if (!this.errorLoading) {
        if (
          this.patientsStatistics?.totalNumberOfPatients.length &&
          this.patientsStatistics?.totalNumberOfPatientsByCivilStatus.length &&
          this.patientsStatistics?.totalNumberOfPatientsByGender.length &&
          this.patientsStatistics?.totalNumberOfPatientsByChildPosition
            .length &&
          this.patientsStatistics?.totalNumberOfPatientsByChildren.length
        ) {
          this.civilStatusValues = [];
          this.genderValues = [];
          this.childPositionValues = [];
          this.childrenValues = [];

          this.totalNumberOfPatients = Number(
            this.patientsStatistics.totalNumberOfPatients[0].total
          );
          await this.loadCivilStatusStatistics();
          await this.loadGenderStatistics();
          await this.loadChildPositionStatistics();
          await this.loadChildrenStatistics();
        }

        await this.formatFormStatistics();
      }
      this.loading = false;
    },
    async loadCivilStatusStatistics() {
      for await (let civilStatusOption of this.civilStatusOptions) {
        let civilStatusStatistic = this.patientsStatistics.totalNumberOfPatientsByCivilStatus.find(
          metric => metric.civilstatus === civilStatusOption
        );
        if (civilStatusStatistic) {
          this.civilStatusValues.push(Number(civilStatusStatistic.total));
        } else {
          this.civilStatusValues.push(0);
        }
      }
    },
    async loadGenderStatistics() {
      for await (let genderOption of this.genderOptions) {
        let genderStatistic = this.patientsStatistics.totalNumberOfPatientsByGender.find(
          metric => metric.gender === genderOption
        );
        if (genderStatistic) {
          this.genderValues.push(Number(genderStatistic.total));
        } else {
          this.genderValues.push(0);
        }
      }
    },
    async loadChildPositionStatistics() {
      for await (let childPositionOption of this.childPositionOptions) {
        let childPositionStatistic = this.patientsStatistics.totalNumberOfPatientsByChildPosition.find(
          metric => metric.children === childPositionOption
        );
        if (childPositionStatistic) {
          this.childPositionValues.push(Number(childPositionStatistic.total));
        } else {
          this.childPositionValues.push(0);
        }
      }
    },
    async loadChildrenStatistics() {
      for await (let childrenOption of this.childrenOptions) {
        let childrenStatistic = this.patientsStatistics.totalNumberOfPatientsByChildren.find(
          metric => metric.children === childrenOption
        );
        if (childrenStatistic) {
          this.childrenValues.push(Number(childrenStatistic.total));
        } else {
          this.childrenValues.push(0);
        }
      }
    },
    async formatFormStatistics() {
      let formStatisticsOptions = null;
      if (
        this.patientsStatistics.totalNumberOfResponsesByActiveOptionOfAForm
          .length
      ) {
        this.optionStatisticsExist = true;
        formStatisticsOptions = {
          id: this.patientsStatistics
            .totalNumberOfResponsesByActiveOptionOfAForm[0].id,
          sections: []
        };
        for await (let section of this.patientsStatistics
          .totalNumberOfResponsesByActiveOptionOfAForm[0].sections) {
          let sectionAux = {
            id: section.id,
            name: section.name,
            position: section.position,
            questions: []
          };
          for await (let question of section.questions) {
            let statistics = { options: [], values: [] };
            for await (let option of question.options) {
              statistics.options.push(option.name);
              statistics.values.push(Number(option.total));
            }
            delete question.options;
            let questionAux = { ...question, statistics };
            sectionAux.questions.push(questionAux);
          }
          formStatisticsOptions.sections.push(sectionAux);
        }
      }
      let formStatisticsBoolean = null;
      if (this.patientsStatistics.totalNumberOfBooleanResponsesByForm.length) {
        this.booleanStatisticsExist = true;
        formStatisticsBoolean = {
          id: this.patientsStatistics.totalNumberOfBooleanResponsesByForm[0].id,
          sections: []
        };
        for await (let section of this.patientsStatistics
          .totalNumberOfBooleanResponsesByForm[0].sections) {
          let sectionAux = {
            id: section.id,
            name: section.name,
            position: section.position,
            questions: []
          };
          for await (let question of section.questions) {
            let statistics = { options: [], values: [] };
            for await (let answer of question.answers) {
              statistics.options.push(
                answer.content === "true" ? "Verdadero" : "Falso"
              );
              statistics.values.push(Number(answer.total));
            }
            delete question.answers;
            let questionAux = { ...question, statistics };
            sectionAux.questions.push(questionAux);
          }
          formStatisticsBoolean.sections.push(sectionAux);
        }
      }
      if (this.optionStatisticsExist && this.booleanStatisticsExist) {
        for await (let optionsSection of formStatisticsOptions.sections) {
          let commonSection = formStatisticsBoolean.sections.find(
            booleanSection => booleanSection.id === optionsSection.id
          );
          if (commonSection) {
            let index = formStatisticsBoolean.sections.indexOf(commonSection);
            formStatisticsBoolean.sections[index].questions = [
              ...formStatisticsBoolean.sections[index].questions,
              ...optionsSection.questions
            ];
          } else {
            formStatisticsBoolean.sections.push(optionsSection);
          }
        }
      }
      if (this.booleanStatisticsExist) {
        this.formStatistics = formStatisticsBoolean;
      } else {
        if (this.optionStatisticsExist) {
          this.formStatistics = formStatisticsOptions;
        }
      }
      for await (let section of this.formStatistics.sections) {
        section.questions.sort((q1, q2) => q1.position - q2.position);
      }
      this.formStatistics.sections.sort((s1, s2) => s1.position - s2.position);
    }
  },
  mounted() {
    this.loadPatientsStatistics();
  }
};
</script>

<style></style>
