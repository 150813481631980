var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_c('v-card',{staticClass:"px-3 pt-3",class:{
      'background-primary': _vm.primary || !_vm.secondary,
      'background-secondary': _vm.secondary
    },attrs:{"elevation":"7","height":"fit-content"}},[_c('v-container',{staticClass:"py-0"},[_c('v-row',{staticClass:"py-0"},[_c('v-col',{staticClass:"py-0 align-end justify-center",attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{staticClass:"font-weight-medium",class:{
                'headline ': this.$vuetify.breakpoint.lgAndUp,
                title: this.$vuetify.breakpoint.mdAndDown
              },attrs:{"cols":this.$vuetify.breakpoint.xsOnly ? '12' : '8',"lg":"6","align":this.$vuetify.breakpoint.xsOnly ? 'center' : ''}},[_vm._v(_vm._s(_vm.title))]),_c('v-col',{staticClass:"shadow d-flex align-end justify-center",class:{
                'display-4': this.$vuetify.breakpoint.lgAndUp,
                'display-2': this.$vuetify.breakpoint.mdAndDown,
                'pl-0': this.$vuetify.breakpoint.xsOnly
              },attrs:{"cols":this.$vuetify.breakpoint.xsOnly ? '12' : '4',"lg":"6"}},[_vm._v(_vm._s(_vm.value))])],1),_c('v-row',{staticClass:"pb-0"},[_c('v-col',{staticClass:"pt-0 pb-4 d-flex align-end justify-end",attrs:{"cols":"12"}},[_c('v-progress-linear',{attrs:{"value":_vm.totalShown,"background-color":"rgb(167, 167, 167)","color":"white"}})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }